import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import QuadCol from '../components/QuadCol';
import BiCol from '../components/BiCol';
import SingleCol from '../components/SingleCol';

import Vans from '../img/vans.jpg';

class IndexPage extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allContentYaml {
              edges {
                node {
                  title
                  photo
                  intro
                  whereWeWork
                  whatWeDo {
                    name
                    photo
                    info
                  }
                  whatWeUseLogos {
                    logo
                    logoName
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <Hero />
            <div className="strip">
              <div className="row">
                <h2>Who we are</h2>
                <p className="intro">
                  {data.allContentYaml.edges[0].node.title}
                </p>
                <img
                  src={data.allContentYaml.edges[0].node.photo}
                  alt="The McAuley Family"
                />
                <p>{data.allContentYaml.edges[0].node.intro}</p>
              </div>
            </div>

            <QuadCol heading="What we do">
              {data.allContentYaml.edges[0].node.whatWeDo.map(weDo => (
                <div key={weDo + `tag`}>
                  <h3>{weDo.name}</h3>
                  <img src={weDo.photo} alt="" />
                  <p>{weDo.info}</p>
                </div>
              ))}
            </QuadCol>

            <BiCol heading="Where we work">
              <p>{data.allContentYaml.edges[0].node.whereWeWork}</p>
              <div>
                <img src={Vans} alt="Our vans" />
              </div>
            </BiCol>

            <div className="strip facebook-strip">
              <div className="row">
                <a
                  className="no-underline button"
                  href="https://www.facebook.com/pg/mcauleyandsons/photos/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See our work on Facebook
                </a>
              </div>
            </div>

            <SingleCol heading="What we use">
              <ul className="logo-cloud">
                {data.allContentYaml.edges[0].node.whatWeUseLogos.map(weUse => (
                  <li>
                    <img src={weUse.logo} alt={weUse.logoName} />
                  </li>
                ))}
              </ul>
            </SingleCol>
          </Layout>
        )}
      />
    );
  }
}

export default IndexPage;
