import React, { Component } from 'react';
import styled from 'styled-components';

const SingleColStyle = styled.div`
  display: block;
`;

export default class SingleCol extends Component {
  render() {
    return (
      <div className="strip">
        <div className="row">
          <h2>{this.props.heading}</h2>
          <SingleColStyle>{this.props.children}</SingleColStyle>
        </div>
      </div>
    );
  }
}
