import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const HeroStyle = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  position: relative;

  .gatsby-image-wrapper {
    width: 100vw;
    height: 100vh;
  }

  h1 {
    background-image: url('/img/hero-translucent-bg.svg');
    background-size: 100% 95%;
    background-position: 0 0.5rem;
    bottom: 12rem;
    color: #2d4b96;
    max-width: 275px;
    text-align: center;
    position: absolute;
    z-index: 1;
    font-size: 2rem;
    overflow: visible;
    width: 275px;
    padding: 3rem;
    text-align: center;
  }
`;

export default () => (
  <StaticQuery
    query={graphql`
      {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <HeroStyle>
        <h1>Painting &amp; decorating services in Belfast &amp; beyond.</h1>
        <Img fluid={data.hero.childImageSharp.fluid} />
      </HeroStyle>
    )}
  />
);
