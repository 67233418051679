import React, { Component } from 'react';
import styled from 'styled-components';

const TriColStyle = styled.div`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  img {
    display: block;
    margin: auto;
    width: 100%;
  }
`;

export default class TriCol extends Component {
  render() {
    return (
      <div className="strip">
        <div className="row">
          <h2>{this.props.heading}</h2>
          <TriColStyle>{this.props.children}</TriColStyle>
        </div>
      </div>
    );
  }
}
