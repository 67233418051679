import React, { Component } from 'react';
import styled from 'styled-components';

const BiColStyle = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    > .align-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default class BiCol extends Component {
  render() {
    return (
      <div className="strip">
        <div className="row">
          <h2>{this.props.heading}</h2>
          <BiColStyle>{this.props.children}</BiColStyle>
        </div>
      </div>
    );
  }
}
